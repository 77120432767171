(function() {
    'use strict';

    angular.module('uabDefaultVariable', []);
})();
(function() {
    'use strict';

    angular.module('uabDefaultVariable').controller(
        'DefaultVariableController',
        DefaultVariableController
    );

    DefaultVariableController.$inject = [
        'DefaultVariableService'
    ];

    function DefaultVariableController(
        DefaultVariableService
    ) {
        var DefaultVariableController = this;

        DefaultVariableController.exist = exist;
        function exist(candidate, paths) {
            return DefaultVariableService.exist(candidate, paths);
        }

        DefaultVariableController.get = get;
        function get(candidate, runnerUp) {
            return DefaultVariableService.get(candidate, runnerUp);
        }

        DefaultVariableController.getArray = getArray;
        function getArray(candidate) {
            return DefaultVariableService.getArray(candidate);
        }

        DefaultVariableController.getInteger = getInteger;
        function getInteger(candidate, runnerUp) {
            return DefaultVariableService.getInteger(candidate, runnerUp);
        }

        DefaultVariableController.getObject = getObject;
        function getObject(candidate) {
            return DefaultVariableService.getObject(candidate);
        }

        DefaultVariableController.getPromise = getPromise;
        function getPromise(candidate) {
            return DefaultVariableService.getPromise(candidate);
        }

        DefaultVariableController.getString = getString;
        function getString(candidate) {
            return DefaultVariableService.getString(candidate);
        }

        DefaultVariableController.isBoolean = isBoolean;
        function isBoolean(candidate) {
            return DefaultVariableService.isBoolean(candidate);
        }

        DefaultVariableController.isFunction = isFunction;
        function isFunction(candidate) {
            return DefaultVariableService.isFunction(candidate);
        }

        DefaultVariableController.toggle = toggle;
        function toggle(value, defaultValue) {
            return DefaultVariableService.toggle(value, defaultValue);
        }

        DefaultVariableController.toCamelCase = toCamelCase;
        function toCamelCase(string) {
            return DefaultVariableService.toCamelCase(string);
        }

        DefaultVariableController.toUnderScore = toUnderScore;
        function toUnderScore(string) {
            return DefaultVariableService.toUnderScore(string);
        }

    }
})();
(function() {
    'use strict';

    angular.module('uabDefaultVariable').service('DefaultVariableService', DefaultVariableService);

    DefaultVariableService.$inject = [
        '$timeout'
    ];

    function DefaultVariableService(
        $timeout
    ) {
        var DefaultVariableService = this;

        DefaultVariableService.mergeArrays = mergeArrays;
        function mergeArrays(alpha, beta, removeDuplicates) {
            alpha = alpha.concat(beta);

            removeDuplicates = DefaultVariableService.get(
                removeDuplicates,
                true
            );

            if (removeDuplicates) {
                alpha = DefaultVariableService.removeDuplicates(alpha);
            }

            return alpha;
        }

        DefaultVariableService.isStringNumber = isStringNumber;
        function isStringNumber(string) {
            if (DefaultVariableService.isString(string)) {
                return /^\d+\.\d+$/.test(string) || /^\d+$/.test(string);
            }

            return false;
        }

        DefaultVariableService.removeDuplicates = removeDuplicates;
        function removeDuplicates(array) {
            var returnVar = array.concat([]);

            for (var i = 0; i < returnVar.length; ++i) {
                var iValue = returnVar[i];

                for (var j = i + 1; j < returnVar.length; ++j) {
                    var jValue = returnVar[j];

                    var isDuplicate = true;
                    if (DefaultVariableService.isObject(iValue) && DefaultVariableService.isObject(jValue)) {
                        if (iValue.id !== jValue.id) {
                            isDuplicate = false;
                        }
                    } else if (iValue !== jValue) {
                        isDuplicate = false;
                    }


                    if (isDuplicate) {
                        returnVar.splice(j--, 1);
                    }
                }
            }

            return returnVar;
        }

        DefaultVariableService.mergeObjectsDuplicateFields = mergeObjectsDuplicateFields;
        function mergeObjectsDuplicateFields(alpha, beta) {
            alpha = DefaultVariableService.get(alpha, {});
            beta = DefaultVariableService.get(beta, {});

            var keys = Object.keys(beta);

            var keysLength = keys.length;
            for (var i = 0; i < keysLength; i++) {
                var key = keys[i];

                if (key !== 'id') {
                    var alphaValue = DefaultVariableService.get(alpha[key], false);
                    if (alphaValue) {
                        if (DefaultVariableService.isStringNumber(alphaValue)) {
                            alphaValue = DefaultVariableService.getInteger(
                                alphaValue
                            );
                        }

                        alphaValue = DefaultVariableService.getArray(alphaValue);
                    }

                    var betaValue = DefaultVariableService.get(beta[key], false);
                    if (betaValue) {
                        if (DefaultVariableService.isStringNumber(betaValue)) {
                            betaValue = DefaultVariableService.getInteger(
                                betaValue
                            );
                        }

                        betaValue = DefaultVariableService.getArray(betaValue);
                    }

                    if (alphaValue && betaValue) {
                        alphaValue = DefaultVariableService.mergeArrays(alphaValue, betaValue, true);

                        alpha[key] = alphaValue;
                    } else if (alphaValue) {
                        alpha[key] = alphaValue;
                    } else if (betaValue) {
                        alpha[key] = betaValue;
                    }
                }
            }

            return alpha;
        }

        DefaultVariableService.convertToArray = convertToArray;
        function convertToArray(items) {
            if (!DefaultVariableService.isArray(items)) {
                items = [items];
            }

            return items;
        }

        DefaultVariableService.exist = exist;
        function exist(object, paths) {
            var value = null;
            if (object) {
                paths = DefaultVariableService.convertToArray(paths);

                var pathsLength = paths.length;
                for (var i = 0; i < pathsLength; i++) {
                    var path = paths[i];

                    value = DefaultVariableService.getObjectPropertyValue(object, path);

                    if (value === null) {
                        return false;
                    }
                }

                return true;
            }

            return false;
        }

        DefaultVariableService.get = get;
        function get(candidate, runnerUp) {
            if (!DefaultVariableService.isDefined(runnerUp)) {
                runnerUp = false;
            }

            return (DefaultVariableService.isDefined(candidate)) ?
                candidate : runnerUp;
        }

        DefaultVariableService.getArray = getArray;
        function getArray(candidate) {
            if (DefaultVariableService.isDefined(candidate)) {
                if (!DefaultVariableService.isArray(candidate)) {
                    candidate = [
                        candidate
                    ];
                }
            } else {
                candidate = [];
            }

            return candidate;
        }

        DefaultVariableService.getBoolean = getBoolean;
        function getBoolean(candidate, runnerUp) {
            if (DefaultVariableService.isString(candidate)) {
                candidate = candidate.toLowerCase();

                if (candidate === 't' || candidate === 'true' || candidate === '1') {
                    candidate = true;
                } else if (candidate === 'f' || candidate === 'false' || candidate === '0') {
                    candidate = false;
                }
            } else if (DefaultVariableService.isInteger(candidate)) {
                if (candidate === 0) {
                    candidate = false;
                } else if (candidate === 1) {
                    candidate = true;
                }
            }

            return (DefaultVariableService.isBoolean(candidate)) ?
                candidate : DefaultVariableService.getBoolean(runnerUp, false);
        }

        DefaultVariableService.getEmptyObject = getEmptyObject;
        function getEmptyObject(candidate) {
            return (DefaultVariableService.isDefined(candidate)) ?
                candidate : {};
        }

        DefaultVariableService.getInteger = getInteger;
        function getInteger(candidate, runnerUp) {
            if (DefaultVariableService.isDefined(runnerUp)) {
                runnerUp = DefaultVariableService.getInteger(runnerUp);
            } else {
                runnerUp = 0;
            }

            var isNumber = DefaultVariableService.isStringNumber(candidate);
            if (isNumber) {
                candidate = parseFloat(candidate);
            }

            return (DefaultVariableService.isNumber(candidate)) ?
                candidate : runnerUp;
        }

        DefaultVariableService.getObject = getObject;
        function getObject(candidate) {
            return (DefaultVariableService.isObject(candidate)) ?
                 candidate : { id: 0 };
        }

        DefaultVariableService.getObjectPropertyValue = getObjectPropertyValue;
        function getObjectPropertyValue(object, path) {
            if (DefaultVariableService.isString(path)) {
                var sections = path.split('.');

                if (DefaultVariableService.isArray(sections)) {
                    var sectionsLength = sections.length;
                    for (var j = 0; j < sectionsLength; j++) {
                        var section = sections[j];
                        if (section in object === false) {
                            section = DefaultVariableService.toUnderScore(section);
                        }

                        if (section in object) {
                            object = object[section];
                            if (DefaultVariableService.isArray(object)) {
                                if (object.length !== 0) {
                                    object = object[0];
                                }
                            }
                        } else {
                            return null;
                        }
                    }

                    return object;
                }
            }

            return null;
        }

        DefaultVariableService.getPromise = getPromise;
        function getPromise(candidate) {
            if (DefaultVariableService.isDefined(candidate)) {
                return candidate;
            } else {
                return function() {
                    return $timeout(
                        function(parameter) {
                            return parameter;
                        },
                        0,
                        false
                    );
                };
            }
        }

        DefaultVariableService.getString = getString;
        function getString(candidate, runnerUp) {
            if (DefaultVariableService.isDefined(runnerUp)) {
                runnerUp = DefaultVariableService.getString(runnerUp);
            } else {
                runnerUp = '';
            }

            if (!DefaultVariableService.isString(candidate)) {
                if (DefaultVariableService.isDefined(candidate)) {
                    return candidate + '';
                } else {
                    return runnerUp;
                }
            }

            return candidate;
        }

        DefaultVariableService.isArray = isArray;
        function isArray(candidate) {
            return DefaultVariableService.isDefined(candidate) && Object.prototype.toString.call(candidate) === '[object Array]';
        }

        DefaultVariableService.isBoolean = isBoolean;
        function isBoolean(candidate) {
            return DefaultVariableService.isDefined(candidate) && typeof candidate === 'boolean';
        }

        DefaultVariableService.isDefined = isDefined;
        function isDefined(candidate) {
            return typeof candidate !== 'undefined' && candidate !== null;
        }

        DefaultVariableService.isFunction = isFunction;
        function isFunction(candidate) {
            return typeof candidate !== 'undefined' && typeof candidate === 'function';
        }

        DefaultVariableService.isInteger = isInteger;
        function isInteger(candidate) {
            return DefaultVariableService.isNumber(candidate) && candidate % 1 === 0;
        }

        DefaultVariableService.isNumber = isNumber;
        function isNumber(candidate) {
            return DefaultVariableService.isDefined(candidate) && typeof candidate === 'number';
        }

        DefaultVariableService.isObject = isObject;
        function isObject(candidate) {
            return DefaultVariableService.isDefined(candidate) && DefaultVariableService.getInteger(candidate.id, 0) !== 0;
        }

        DefaultVariableService.isString = isString;
        function isString(candidate) {
            return typeof candidate === 'string';
        }

        DefaultVariableService.isUrl = isUrl;
        function isUrl(candidate) {
            return /^(http[s]?:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(candidate);
        }

        DefaultVariableService.mergeObjects = mergeObjects;
        function mergeObjects(alpha, beta, override) {
            alpha = DefaultVariableService.get(alpha, {});
            beta = DefaultVariableService.get(beta, {});
            override = DefaultVariableService.get(override, false);

            var keys = Object.keys(beta);

            var keysLength = keys.length;
            for (var i = 0; i < keysLength; i++) {
                var key = keys[i];
                if (!DefaultVariableService.isDefined(alpha[key]) || override) {
                    alpha[key] = beta[key];
                } else {
                    if (DefaultVariableService.isObject(alpha[key])) {
                        alpha[key] = DefaultVariableService.mergeObjects(
                            alpha[key],
                            beta[key]
                        );
                    } else if (DefaultVariableService.isArray(alpha[key]) && DefaultVariableService.isArray(beta[key])) {
                        var alphaLength = alpha[key].length;

                        for (var a = 0; a < alphaLength; a++) {
                            var aElement = alpha[key][a];

                            if (DefaultVariableService.isObject(aElement)) {
                                var alphaContains = false;
                                var betaLength = beta[key].length;

                                for (var b = 0; b < betaLength; b++) {
                                    var bElement = beta[key][b];

                                    if (DefaultVariableService.isObject(bElement)) {
                                        var aId = DefaultVariableService.getInteger(aElement.id, 0);
                                        var bId = DefaultVariableService.getInteger(bElement.id, 0);

                                        if (aId === bId) {
                                            alphaContains = true;

                                            alpha[key][a] = DefaultVariableService.mergeObjects(
                                                alpha[key][a],
                                                beta[key][b]
                                            );
                                        }
                                    } else if (!DefaultVariableService.isDefined(bElement)) {
                                        alphaContains = true;
                                    }
                                }

                                if (!alphaContains) {
                                    alpha[key].push(beta[key][b]);
                                }
                            }
                        }
                    }
                }
            }

            return alpha;
        }

        DefaultVariableService.sortObjects = sortObjects;
        function sortObjects(objects, property, isAscending) {
            objects = DefaultVariableService.getArray(objects);

            objects.sort(
                function compare(a,b) {
                    a = DefaultVariableService.getObject(a);
                    b = DefaultVariableService.getObject(b);

                    var aValue = DefaultVariableService.getObjectPropertyValue(
                        a,
                        property
                    );

                    var bValue = DefaultVariableService.getObjectPropertyValue(
                        b,
                        property
                    );

                    var returnVar = 0;
                    if (aValue === bValue) {
                        returnVar = 0;
                    } else if (aValue < bValue || aValue === null) {
                        returnVar = -1;
                    } else if (aValue > bValue || bValue === null) {
                        returnVar = 1;
                    }

                    if (!isAscending) {
                        returnVar = returnVar * -1;
                    }

                    return returnVar;
                }
            );

            return objects;
        }

        DefaultVariableService.stripHtml = stripHtml;
        function stripHtml(string) {
            return string ? String(string).replace(/<[^>]+>/gm, '') : '';
        }

        DefaultVariableService.toggle = toggle;
        function toggle(value, defaultValue) {
            return !DefaultVariableService.get(
                value,
                defaultValue
            );
        }

        DefaultVariableService.toCamelCase = toCamelCase;
        function toCamelCase(string) {
            return string.toLowerCase().replace(/_(.)/g, function(match, group1) {
                return group1.toUpperCase();
            });
        }

        DefaultVariableService.toPascalCase = toPascalCase;
        function toPascalCase(string) {
            string = DefaultVariableService.toCamelCase(string);

            if (string.length !== 0) {
                var firstChar = string.substring(0, 1);
                string = string.substring(1);

                firstChar = firstChar.toUpperCase();

                string = firstChar + string;
            }

            return string;
        }

        DefaultVariableService.toUnderScore = toUnderScore;
        function toUnderScore(string) {
            var result = string.replace(
                /([A-Z])/g,
                function($1) {
                    return "_"+$1.toLowerCase();
                }
            );

            var prefix = result.substring(0, 1);
            if (prefix === '_') {
                return result.substring(1);
            } else {
                return result;
            }
        }

        return DefaultVariableService;
    }
})();

(function() {
    'use strict';

    angular.module('uabDefaultVariable').filter('stripHtml', stripHtml);

    stripHtml.$inject = [
        'DefaultVariableService'
    ];

    function stripHtml(
        DefaultVariableService
    ) {
        return function(string) {
            return DefaultVariableService.stripHtml(string);
        };
    }
})();